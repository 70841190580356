<template>
  <div class="bk-page" id="sysUserLst">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="80" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="用户名称">
            <Input v-model="searchData1.UserName" />
          </FormItem>
          <FormItem label="状态">
            <Select v-model="searchData1.Status">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.user.StatusLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="实验室">
            <Select v-model="searchData1.LabId">
              <span slot="prefix">
                <Icon class="bk-load" type="ios-loading" v-if="LabLoading"></Icon>
              </span>
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns" style="width:220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.OrgUserAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <add @on-add="onChangePage(1)" ref="add" />
    <edit @on-upd="reqTable" ref="edit" />
    <set-pwd ref="setPwd" />
    <set-role-group @on-post="reqTable" ref="setRoleGroup" />
  </div>
</template>
<script>
import add from "./add";
import edit from "./edit";
import setPwd from "./set-pwd";
import setRoleGroup from "./set-role-group";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { add, edit, setPwd, setRoleGroup },
  mixins: [SelectLabId],
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "用户名", key: "UserName" },
        { title: "用户帐号", key: "UserCode" },
        // {
        //   title: "用户类型",
        //   key: "UserType",
        //   render: (h, params) => {
        //     return h("span", this.$designConst.user.Type[params.row.UserType]);
        //   },
        // },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status: this.$designConst.user.StatusBadge[params.row.Status],
                text: this.$designConst.user.Status[params.row.Status],
              },
            });
          },
        },
        { title: "部门", key: "DeptName" },
        { title: "实验室", key: "LabName" },
        { title: "手机号", width: 125, key: "Mobile" },
        { title: "角色", key: "RoleNames" },
        {
          title: "操作",
          width: 160,
          align: "right",
          render: (h, params) => {
            let a = [];
            if (this.$able.map.OrgUserSetRole) {
              a.push(
                h(
                  "span",
                  {
                    style: { cursor: "pointer", color: "#2d8cf0" },
                    on: {
                      click: () => {
                        this.$refs.setRoleGroup.open(params.row.Id);
                      },
                    },
                  },
                  "角色"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (this.$able.map.OrgUserPassword) {
              a.push(
                h("Icon", {
                  props: { type: "md-key", size: 18 },
                  attrs: { title: "修改密码" },
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      this.$refs.setPwd.open(params.row.Id);
                    },
                  },
                })
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.OrgUserUpd ? "md-create" : "md-open",
                  size: 18,
                },
                attrs: { title: this.$able.map.OrgUserUpd ? "编辑" : "详情" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.OrgUserDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      this.initSearch();
      this.reqLab();
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.OrgUserQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          UserType: 1,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BeikeUser/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // ==========
    toAdd() {
      this.$refs.add.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.UserName),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BeikeUser/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>