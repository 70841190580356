<template>
  <div id="sysUserAdd">
    <Modal @on-cancel="close" class="sys-user-add-modal" v-model="flag" width="600">
      <p slot="header">系统用户 - 新增</p>
      <Form
        :label-width="120"
        :model="formd"
        :rules="rules"
        @submit.native.prevent
        inline
        ref="form"
      >
        <FormItem label="用户名：" prop="UserName" required>
          <Input v-model="formd.UserName" />
        </FormItem>
        <FormItem label="用户帐号：" prop="UserCode" required>
          <Input v-model="formd.UserCode" />
        </FormItem>
        <FormItem label="用户密码：" prop="Password" required>
          <Input type="password" v-model="formd.Password" />
        </FormItem>
        <FormItem label="确认密码：" prop="Password2" required>
          <Input type="password" v-model="formd.Password2" />
        </FormItem>
        <!-- <FormItem label="用户类型：" required prop="UserType">
          <Select v-model="formd.UserType" @on-change="onUserType">
            <Option v-for="item in $designConst.user.TypeLst" :key="item.key" :value="item.key">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>-->
        <FormItem label="部门：">
          <Select
            :disabled="formd.UserType!=1"
            @on-change="onDept"
            clearable
            label-in-value
            v-model="formd.DeptId"
          >
            <Option :key="item.Id" :value="item.Id" v-for="item in deptLst">{{ item.DeptName }}</Option>
          </Select>
        </FormItem>
        <FormItem label="实验室：">
          <Select clearable v-model="formd.LabId">
            <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
          </Select>
        </FormItem>
        <FormItem label="手机号：">
          <Input v-model="formd.Mobile" />
        </FormItem>
        <FormItem label="固话：">
          <Input v-model="formd.Tel" />
        </FormItem>
        <FormItem label="Email：">
          <Input v-model="formd.Email" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" type="text">取消</Button>
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import SelectLabId from "@/components/select-labid.js";
export default {
  mixins: [SelectLabId],
  data() {
    return {
      flag: false,
      posting: false,
      formd: { UserType: 1 },
      rules: {
        UserType: [{ required: true, type: "number", message: " " }],
        UserName: [{ required: true, message: " " }],
        UserCode: [{ required: true, message: " " }],
        Password: [{ required: true, message: " " }],
        Password2: [
          {
            validator: (rule, value, callback) => {
              if (!this.formd.Password2) {
                callback(new Error(" "));
              } else if (this.formd.Password != this.formd.Password2) {
                callback(new Error("与用户密码不一致"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      deptLst: [],
    };
  },
  methods: {
    open() {
      this.flag = true;
      this.reqDept();
      this.reqLab();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = { UserType: 1 };
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    postData() {
      this.posting = true;
      let param = this.formd;
      param.Status = 1;
      delete param.Password2;
      this.$axios
        .post("/Api/BeikeUser/Add", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.close();
            this.$emit("on-add");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    // ==========
    onUserType(value) {
      if (value != 1) {
        this.formd.DeptId = "";
        this.formd.DeptName = "";
      }
    },
    // ==========
    reqDept() {
      this.$axios.get("/Api/OrgDept/QueryAll").then((res) => {
        if (res.result) {
          this.deptLst = res.data;
        }
      });
    },
    onDept(obj) {
      if (obj) {
        this.formd.DeptName = obj.label;
      } else {
        this.formd.DeptName = "";
      }
    },
  },
};
</script>
<style lang="less">
.sys-user-add-modal {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>
