<template>
  <div class="UserCfg">
    <Modal class="user-cfg-modal" :mask-closable="failed" @on-cancel="close" title="设置更多实验室权限" v-model="flag">
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="reqMy">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <CheckboxGroup v-model="labSel">
        <Checkbox :key="i" :label="a.Id" border v-for="(a,i) in labLst">{{a.Name}}</Checkbox>
      </CheckboxGroup>
      <div slot="footer">
        <Button @click="close" type="text">取消</Button>
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userid: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      labLst: [],
      labSel: [],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.userid = id;
      this.reqMy();
    },
    close() {
      this.userid = 0;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.labSel = [];
    },
    reqMy() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BeikeUser/GetLabsCfg", { params: { UserId: this.userid } })
        .then((res) => {
          if (res.result) {
            if (res.data) {
              this.labSel = res.data.split(",").map((v) => v * 1);
            }
            this.reqAll();
          } else {
            this.loading = false;
            this.failed = true;
            if (err.errormsg) {
              this.$Message.error(err.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    reqAll() {
      this.$axios
        .get("/Api/BaseLabs/QueryAll")
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.labLst = res.data || [];
          } else {
            this.$Message.error(res.errormsg);
            this.failed = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    ok() {
      this.posting = true;
      this.$axios
        .post("/Api/BeikeUser/SaveLabsCfg", {
          UserId: this.userid,
          CfgJsonData: this.labSel.join(","),
        })
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("设置成功");
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
.user-cfg-modal {
  .ivu-checkbox-wrapper {
    margin-bottom: 8px;
  }
}
</style>
