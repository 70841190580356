<template>
  <div id="sysUserSetRoleGroup">
    <Modal
      class="sys-user-set-role-group-modal"
      v-model="flag"
      @on-cancel="close">
      <p slot="header">系统用户 - 分配角色</p>
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="reqRoles">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div style="min-height:240px;">
        <Transfer
          v-if="roles.length"
          :titles="roleTransferTitles"
          :data="roles"
          :target-keys="roleIds"
          :render-format="roleFormat"
          @on-change="onRoles"></Transfer>
      </div>
      <div slot="footer">
        <Button type="text" @click="close">取消</Button>
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      userId: "",
      roles: [], // 原数据
      roleIds: [], // 已选ID
      roleTransferTitles: ["可分配角色", "已分配角色"]
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.userId = id;
      this.reqRoles();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    ok() {
      this.postData();
    },
    // ==========
    reqRoles() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BeikeRole/QueryAll")
        .then(res => {
          if (res.result) {
            this.roles = res.data.map(v => ({ key: v.Id, label: v.RoleName }));
            this.getMyRoles();
          } else {
            this.loading = false;
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
          this.failed = true;
          this.$Message.error("网络错误");
        });
    },
    getMyRoles() {
      this.$axios
        .get("/Api/BeikeRoleGroup/SelectByUserID?userId=" + this.userId)
        .then(res => {
          this.loading = false;
          if (res.result) {
            this.$nextTick(() => {
              this.roleIds = res.data.map(v => v.RoleId);
            });
          } else {
            this.failed = true;
          }
        })
        .catch(err => {
          this.loading = false;
          this.failed = true;
        });
    },
    roleFormat(item) {
      return item.label;
    },
    onRoles(targetKeys, direction, moveKeys) {
      this.roleIds = targetKeys;
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/Api/BeikeRoleGroup/AddEx", {
          UserId: this.userId,
          Roles: this.roleIds.map(v => ({ UserId: this.userId, RoleId: v }))
        })
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.$emit("on-post");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch(err => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    }
  }
};
</script>
<style lang="less">
.sys-user-set-role-group-modal {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>

