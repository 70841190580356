<template>
  <div id="sysUserEdit">
    <Modal
      :mask-closable="failed"
      :styles="{top:'50px'}"
      @on-cancel="close"
      class="sys-user-edit-modal"
      v-model="flag"
    >
      <p slot="header">系统用户 - 编辑</p>

      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <Form
        :label-width="100"
        :model="formd"
        :rules="rules"
        @submit.native.prevent
        inline
        ref="form"
      >
        <FormItem label="用户名：" prop="UserName" required>
          <Input v-if="ABLE" v-model="formd.UserName" />
          <div v-else>{{formd.UserName}}</div>
        </FormItem>
        <FormItem label="用户帐号：" prop="UserCode" required>
          <Input v-if="ABLE" v-model="formd.UserCode" />
          <div v-else>{{formd.UserCode}}</div>
        </FormItem>
        <!-- <FormItem label="用户类型：" prop="UserType" required>
          <Select v-if="ABLE" v-model="formd.UserType">
            <Option
              :key="item.key"
              :value="item.key"
              v-for="item in $designConst.user.TypeLst"
            >{{ item.name }}</Option>
          </Select>
          <div v-else>{{$designConst.user.Type[formd.UserType]}}</div>
        </FormItem>-->
        <FormItem label="部门：">
          <Select
            :disabled="formd.UserType!=1"
            @on-change="onDept"
            clearable
            label-in-value
            v-if="ABLE"
            v-model="formd.DeptId"
          >
            <Option :key="item.Id" :value="item.Id" v-for="item in deptLst">{{ item.DeptName }}</Option>
          </Select>
          <div v-else>{{formd.DeptName}}</div>
        </FormItem>
        <FormItem label="实验室：">
          <Select clearable v-if="ABLE" v-model="formd.LabId">
            <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
          </Select>
          <div v-else>{{formd.LabName}}</div>
        </FormItem>
        <FormItem label="手机号：">
          <Input v-if="ABLE" v-model="formd.Mobile" />
          <div v-else>{{formd.Mobile}}</div>
        </FormItem>
        <FormItem label="固话：">
          <Input v-if="ABLE" v-model="formd.Tel" />
          <div v-else>{{formd.Tel}}</div>
        </FormItem>
        <FormItem label="Email：">
          <Input v-if="ABLE" v-model="formd.Email" />
          <div v-else>{{formd.Email}}</div>
        </FormItem>
        <FormItem class="long" label="状态：">
          <RadioGroup v-if="ABLE" v-model="formd.Status">
            <Radio
              :key="item.key"
              :label="item.key"
              v-for="item in $designConst.user.StatusLst"
            >{{ item.name }}</Radio>
          </RadioGroup>
          <div v-else>{{$designConst.user.Status[formd.Status]}}</div>
        </FormItem>
        <FormItem class="long" label="备注：">
          <Input :rows="2" placeholder="请输入备注" type="textarea" v-if="ABLE" v-model="formd.Remark" />
          <div v-else>{{formd.Remark}}</div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button
          @click="toCfg"
          class="fl"
          ghost
          type="primary"
          v-if="$able.map.OrgUserSetLabs&&formd.Id"
        >设置更多实验室权限</Button>
        <Button @click="close" type="text">关闭</Button>
        <Button :loading="posting" @click="ok" type="primary" v-if="ABLE">确定</Button>
      </div>
    </Modal>
    <cfg ref="cfg" />
  </div>
</template>
<script>
import SelectLabId from "@/components/select-labid.js";
import cfg from "./cfg.vue";
export default {
  mixins: [SelectLabId],
  components: { cfg },
  data() {
    return {
      ABLE: false,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: { Status: 1 },
      rules: {
        UserName: [{ required: true, message: " " }],
        UserCode: [{ required: true, message: " " }],
        UserType: [{ required: true, type: "number", message: " " }],
      },
      deptLst: [],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.OrgUserUpd;
        this.formd.Id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.OrgUserAdd;
      }
      this.reqLab();
      this.reqDept();
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = { Status: 1 };
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    reqDept() {
      this.$axios.get("/Api/OrgDept/QueryAll").then((res) => {
        if (res.result) {
          this.deptLst = res.data;
        }
      });
    },
    onDept(obj) {
      if (obj) {
        this.$set(this.formd, "DeptName", obj.label);
      } else {
        this.$set(this.formd, "DeptName", "");
      }
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BeikeUser/GetById", { params: { Id: this.formd.Id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.failed = false;
            this.formd = res.data;
          } else {
            this.failed = true;
            if (err.errormsg) {
              this.$Message.error(err.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    postData() {
      this.posting = true;
      let param = this.formd;
      this.$axios
        .post("/Api/BeikeUser/Update", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("编辑成功");
            this.close();
            this.$emit("on-upd");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toCfg() {
      this.$refs.cfg.open(this.formd.Id);
    },
  },
};
</script>
<style lang="less">
.sys-user-edit-modal {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>

